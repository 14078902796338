import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import Slider from "react-slick";
import "components/HowWorks/howWorks.scss";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import Arrow from "constant/arrow";

// import quickAndEasyFunding from "assets/images/howItWorks/quickAndEasy.png";
// import acceleratingSuccess from "assets/images/howItWorks/accelerating.png";
import credilinqRelevant from "assets/images/howItWorks/credilinqRelevant.png";
import credilinqExist from "assets/images/howItWorks/credilinqExist.png";
import gameChangingBenfits from "assets/images/howItWorks/gameChangingBenfits.png";
import diveIntoFintech from "assets/images/howItWorks/diveIntoFintech.png";
import viewMoreArrow from "assets/images/howItWorks/gradientArrowRight.svg";

import readyToShort from "assets/images/howItWorks/readyToShort.png";
import skyRocket from "assets/images/howItWorks/skyRocket.png";
import boostTikTok from "assets/images/howItWorks/boostTikTok.png";
// import silverLeftArrow from "assets/images/howItWorks/leftNormalIcon.svg";
// import leftActiveIcon from "assets/images/howItWorks/leftActiveIcon.svg";
// import gradientRightArrow from "assets/images/howItWorks/rightActiveIcon.svg";
// import rightNormalIcon from "assets/images/howItWorks/rightNormalIcon.svg";
// import playVideo from "assets/images/howItWorks/playVideo.svg";
// import gradientPlayButton from "assets/images/howItWorks/gradientPlayButton.png";
import close from "assets/images/howItWorks/vedioClose.png";
// import useScreenMobile from "hooks/useScreenMobile";

const amazonVideosData = [
  //   {
  //     heading: "Quick & Easy Funding for Global Amazon Sellers",
  //     cardImage: quickAndEasyFunding,
  //     videoSrc: "https://www.youtube.com/embed/uN0NNrPr9oQ"
  //   },
  //   {
  //     heading:
  //       "Accelerating Success: Scale Your Amazon Business Through Strategic Funding",
  //     cardImage: acceleratingSuccess,
  //     videoSrc: "https://www.youtube.com/embed/CgnQB9hnszA"
  //   },
  {
    heading: "Why CrediLinq is more relevant now?",
    cardImage: credilinqRelevant,
    videoSrc: "https://www.youtube.com/embed/0smCBlKN5pc"
  },
  {
    heading: "Why CrediLinq exist?",
    cardImage: credilinqExist,
    videoSrc: "https://www.youtube.com/embed/aA3N9z7bUb0"
  },
  {
    heading:
      "Discover the game-changing benefits of CrediLinq's Amazon seller financing",
    cardImage: gameChangingBenfits,
    videoSrc: "https://www.youtube.com/embed/6hgXjfNY-Tk"
  },
  {
    heading:
      "Dive into the world of Fintech and SME lending with the founders of CrediLinq.AI",
    cardImage: diveIntoFintech,
    videoSrc: "https://www.youtube.com/embed/E6HHrdCJqi0"
  }
];

const tiktokVideosData = [
  {
    src: "https://www.youtube.com/embed/RKqsPp55eoU",
    img: readyToShort
  },
  {
    src: "https://www.youtube.com/embed/sgwKerv-23k",
    img: skyRocket
  },
  {
    src: "https://www.youtube.com/embed/GMTxWbhLmJw",
    img: boostTikTok
  }
];

const HowWorks = ({ howWorksData }) => {
  //   const [currentStep, setCurrentStep] = useState(-1);
  const [shodalModal, setShowModal] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  console.log(activeVideo, "activeVideo");

  //   const isTab = useScreenMobile({ width: 768 });
  //   const [carouselIndex, setCarouselIndex] = useState(0);

  const location = useLocation();
  const isAmazonSeller = location.pathname === "/seller-financing";
  const isCLIISAmazonSeller = location.pathname === "/cl-lls/seller-financing";
  const isTic = location.pathname === "/tiktok-shop-seller-financing";

  //   const handleClick = (number) => {
  //     setCurrentStep(number === currentStep ? 0 : number);
  //   };

  //   useEffect(() => {
  //     setCurrentStep(0);
  //   }, []);

  //   useEffect(() => {
  //     const timer = setTimeout(
  //       () => {
  //         setCurrentStep((prevStep) =>
  //           prevStep === howWorksData.length - 1 ? -1 : prevStep + 1
  //         );
  //       },
  //       currentStep === -1 ? 100 : 3000
  //     );

  //     return () => {
  //       clearTimeout(timer);
  //     };
  //   }, [howWorksData, currentStep]);

  useEffect(() => {
    if (shodalModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [shodalModal]);

  const handleModalAndVideo = (index) => {
    setShowModal(true);
    setActiveVideo(index);
  };

  const closeVideoModal = () => {
    setShowModal(false);
    setActiveVideo(null);
  };

  //   const settings = {
  //     dots: false,
  //     arrows: true,
  //     infinite: false,
  //     speed: 500,
  //     autoplay: false,
  //     slidesToShow: 2,
  //     slidesToScroll: 1,
  //     pauseOnFocus: false,
  //     pauseOnHover: false,
  //     autoplaySpeed: 8000,
  //     spaceBetween: 40,
  //     afterChange: (current) => {
  //       setCarouselIndex(current);
  //     },
  //     prevArrow: (
  //       <img
  //         src={carouselIndex > 0 ? leftActiveIcon : silverLeftArrow}
  //         className={`slick-prev ${carouselIndex > 0 ? "disabled" : ""}`}
  //         alt="Previous"
  //       />
  //     ),
  //     nextArrow: (
  //       <img
  //         src={
  //           carouselIndex < videosData.length - (isTab ? 1 : 2)
  //             ? gradientRightArrow
  //             : rightNormalIcon
  //         }
  //         className={`slick-next ${
  //           carouselIndex < videosData.length - (isTab ? 1 : 2) ? "disabled" : ""
  //         }`}
  //         alt="Next"
  //       />
  //     ),
  //     responsive: [
  //       {
  //         breakpoint: 768,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //           spaceBetween: 20
  //         }
  //       }
  //     ]
  //   };

  return (
    <section className="amazonHowWorks">
      <div className="container">
        <div className="amazonHowWorksContainer">
          {isTic && (
            <div className="amazonHowWorksContent">
              <h2 className="commonHeading amazonHowWorksHeading">
                How It&nbsp;
                <span className="commonGradientHeading amazonHowWorksGradientHeading">
                  Works ?
                </span>
              </h2>
              <p>Learn about How CrediLinq helps SME&apos;s</p>
              {/* <Link
                to={"https://production.dtpgpnb5pgnbr.amplifyapp.com/"}
                target={"_blank"}
              >
                <button className="commonButton amazonHowWorksButton">
                  Apply Now <Arrow />
                </button>
              </Link> */}
            </div>
          )}

          {/* {isAmazonSeller && (
            <div className="videoBox">
              <div className="commonHeading videoBoxHeader">
                <h1>
                  How It <span>works ?</span>
                </h1>

                <p>Learn about How CrediLinq helps SME&apos;s </p>
              </div>

              <div className="cardParent">
                <Slider {...settings}>
                  {videosData.map((video, index) => (
                    <div
                      key={index}
                      className="videoBoxCard"
                      onClick={() => handleModalAndVideo(index)}
                    >
                      <div className="imgvideoBox">
                        <img src={video.cardImage} alt="" />
                      </div>

                      <div className="infoButtonBox">
                        <div className="left">
                          <h1>{video.heading}</h1>
                        </div>

                        <div className="right">
                          <img
                            src={video.gradientPlayButton}
                            alt="gradientButton"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )} */}

          {(isAmazonSeller || isCLIISAmazonSeller) && (
            <div className="videoBox">
              <div className="commonHeading videoBoxHeader">
                <h1>
                  How It&nbsp;<span>works ?</span>
                </h1>
                <p>Learn about How CrediLinq helps SME&apos;s </p>
              </div>
              <div className="cardParent">
                {amazonVideosData.map((itm, ind) => (
                  <div
                    key={ind}
                    className="videoBoxCard"
                    onClick={() => handleModalAndVideo(ind)}
                  >
                    <div className="imgvideoBox">
                      <img src={itm.cardImage} alt="" />
                    </div>
                    <div className="infoButtonBox">
                      <div className="left">
                        <h1>{itm.heading}</h1>
                      </div>
                      <div className="right">
                        {/* <img src={gradientPlayButton} alt="gradientButton" /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.setItem("filter", "Podcast");
                  window.open("/articles", "_blank");
                }}
              >
                <p className="viewMore">
                  View More <img src={viewMoreArrow} alt="viewMoreArrow" />
                </p>
              </a>
            </div>
          )}

          {isTic && (
            // <div className="amazonHowWorksProgress">
            //   {howWorksData.map((data, index) => (
            //     <div
            //       className={`amazonHowWorksProgressWrapper ${
            //         data.number <= currentStep ? "activeItem" : ""
            //       }`}
            //       key={data.number}
            //     >
            //       <div className="amazonHowWorksProgressNumber">
            //         <img src={data.img} alt="HowOnBoard" />
            //       </div>
            //       <div
            //         className={`amazonHowWorksProgressContents ${
            //           data.number <= currentStep ? "activeTitle" : ""
            //         }`}
            //         onClick={() => handleClick(data.number)}
            //       >
            //         <h5
            //           className={`activeTitle ${
            //             data.number <= currentStep ? "active" : ""
            //           }`}
            //         >
            //           {data.title}
            //         </h5>
            //         <p>{data.content}</p>
            //         <p className="amazonHowWorksProgressContentsParaGraph">
            //           {data?.para}
            //         </p>
            //       </div>
            //     </div>
            //   ))}
            // </div>
            <div className="tiktokVideosBox">
              {tiktokVideosData.map((_, index) => (
                <div
                  key={index}
                  className="tiktokVideosBoxCard"
                  onClick={() => handleModalAndVideo(index)}
                >
                  <img
                    src={tiktokVideosData[index].img}
                    alt="tikTokShortVideo"
                  />
                </div>
              ))}
            </div>
          )}

          {shodalModal && (
            <div className="vedioModal">
              <div className="modal">
                <div className="vedioBox">
                  <iframe
                    width="100%"
                    height="100%"
                    src={
                      isTic
                        ? tiktokVideosData[activeVideo].src
                        : amazonVideosData[activeVideo]?.["videoSrc"]
                    }
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <img
                    onClick={() => closeVideoModal()}
                    className="close"
                    src={close}
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default HowWorks;
